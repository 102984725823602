<template>
  <div v-if="user && complaint && !loading" class="w-full px-0 py-2">
    <!-- Persona natural -->
    <div v-if="complaint && user.person.person_type !== 2" class="w-full">
      <h2 class="my-5 text-md text-left text-blue"><b>Datos de contacto</b></h2>
      <BaseInput
        type="text"
        label="Nombres"
        v-model="user.first_name"
        required="true"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Apellidos"
        v-model="user.last_name"
        required="true"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Tipo de identificación"
        v-model="user.person.identification_type.name"
        required="true"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Número de identificación"
        placeholder="Ej:123456789"
        v-model="user.person.identification_number"
        required="true"
        :disabled="true"
      />
      <BaseInput
        type="date"
        label="Fecha de nacimiento"
        placeholder=""
        v-model="user.person.birth_date"
        required="true"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Número de celular"
        placeholder="123412341234"
        v-model="user.phone"
        required="true"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Correo electrónico"
        v-model="user.email"
        :disabled="true"
        v-if="user.email"
      />
      <BaseSelect
        ref="department"
        id="department"
        label="Seleccione un departamento"
        :selectOptions="formOptions.optionsDepartments"
        v-model="user.departamento_cod"
        :disabled="true"
        v-if="user.departamento_cod"
      />
      <BaseSelect
        ref="city"
        id="city"
        label="Seleccione un municipio"
        :selectOptions="optionsMunicipalities"
        v-model="user.municipio_cod"
        :disabled="true"
        v-if="user.municipio_cod"
      />
      <BaseInput
        ref="address"
        id="address"
        label="Dirección"
        v-model="user.address"
        :maxlength="50"
        placeholder="Cra. 1 ## 1A-11"
        type="text"
        v-if="user.address"
        :disabled="true"
      />
    </div>

    <!-- Persona júridica -->
    <div v-if="complaint && user.person.person_type == 2" class="w-full">
      <h2 class="my-5 text-md text-left text-blue"><b>Datos de contacto</b></h2>
      <BaseInput
        type="text"
        label="Nombres del representante legal"
        v-model="user.first_name"
        required="true"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Apellidos del representante legal"
        v-model="user.last_name"
        required="true"
        :disabled="true"
      />
      <BaseInput
        ref="nit"
        type="text"
        id="nit"
        label="NIT"
        v-model="user.person.identification_number"
        :disabled="true"
        v-if="user.person.identification_number"
      />
      <BaseInput
        ref="company_name"
        type="text"
        id="razonSocial"
        label="Razón social"
        v-model="user.business_name"
        :disabled="true"
        v-if="user.business_name"
      />
      <BaseInput
        type="text"
        label="Número de celular"
        placeholder="123412341234"
        v-model="user.phone"
        required="true"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Correo electrónico"
        v-model="user.email"
        :disabled="true"
        v-if="user.email"
      />
      <BaseSelect
        ref="department"
        id="department"
        label="Seleccione un departamento"
        :selectOptions="formOptions.optionsDepartments"
        v-model="user.departamento_cod"
        :disabled="true"
        v-if="user.departamento_cod"
      />
      <BaseSelect
        ref="city"
        id="city"
        label="Seleccione un municipio"
        :selectOptions="optionsMunicipalities"
        v-model="user.municipio_cod"
        :disabled="true"
        v-if="user.municipio_cod"
      />
      <BaseInput
        ref="address"
        id="address"
        label="Dirección"
        v-model="user.address"
        :maxlength="50"
        placeholder="Cra. 1 ## 1A-11"
        type="text"
        v-if="user.address"
        :disabled="true"
      />
    </div>

    <div v-if="complaint" class="w-full">
      <div class="grid grid-cols-2 gap-4" v-if="user.person.person_type !== 2">
        <BaseInput
          type="text"
          label="Sexo"
          v-model="complaint.sexo"
          :disabled="true"
        />
        <BaseInput
          type="text"
          label="LGTBIQ+"
          v-model="complaint.lgbtiq"
          :disabled="true"
        />
      </div>
      <BaseRadio
        ref="especialCondicion"
        v-if="user.person.person_type !== 2"
        name="especialCondicion"
        label="Condición especial"
        :radioOptions="optionsYN"
        v-model="complaint.specialCondition"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Cuál"
        v-if="complaint.specialCondition == 1"
        v-model="complaint.condicion_especial"
        :disabled="true"
      />
      <h2 class="my-5 text-md text-left text-blue">
        <b>Detalle de la queja</b>
      </h2>
      <BaseInput
        v-if="complaint"
        type="text"
        id="abroad"
        label="Inconformidad que motiva su queja ocurrió el exterior"
        v-model="abroad"
        :disabled="true"
      />
      <div v-if="abroad == 'Si'">
        <BaseInput
          v-if="complaint"
          type="text"
          id="country"
          label="País donde ocurrio la incidencia"
          v-model="complaint.codigo_pais"
          :disabled="true"
        />
      </div>
      <div v-if="abroad == 'No'">
        <BaseInput
          v-if="complaint"
          type="text"
          id="department"
          label="Departamento donde ocurrio la incidencia"
          v-model="complaint.departamento_cod"
          :disabled="true"
        />
        <BaseInput
          v-if="complaint"
          type="text"
          id="city"
          label="Municipio donde ocurrio la incidencia"
          v-model="complaint.municipio_cod"
          :disabled="true"
        />
      </div>
      <BaseInput
        v-if="complaint"
        type="text"
        id="entity"
        label="Entidad con la que se tuvo la inconformidad"
        v-model="complaint.entidad_cod"
        :disabled="true"
      />
      <BaseInput
        v-if="complaint"
        type="text"
        id="reason"
        label="Motivo de su inconformidad"
        v-model="complaint.macro_motivo_cod"
        :disabled="true"
      />
      <BaseInput
        v-if="complaint"
        type="text"
        id="product"
        label="Queja está relacionada con alguno de los siguientes productos"
        v-model="complaint.producto_cod"
        :disabled="true"
      />
      <BaseInput
        v-if="complaint"
        type="text"
        id="channel"
        label="Canal se originó su inconformidad"
        v-model="complaint.canal_cod"
        :disabled="true"
      />
      <BaseTextarea
        v-if="complaint"
        type="text"
        id="describe"
        label="Detalle del producto:"
        v-model="complaint.producto_nombre"
        :disabled="true"
        maxlength="100"
      />
      <BaseTextarea
        v-if="complaint"
        type="text"
        id="describe"
        label="Hechos brevemente:"
        v-model="complaint.texto_queja"
        :disabled="true"
        maxlength="4500"
      />
      <div class="w-11/12 grid grid-cols-1 gap-2">
        <BaseRadio
          ref="attorney"
          name="attorney"
          label="Actúo en nombre propio o como apoderado"
          :radioOptions="optionsApoderado"
          v-model="complaint.es_apoderado"
          :disabled="true"
        />
      </div>
      <BaseInput
        v-if="complaint.es_apoderado === 2"
        type="text"
        id="apoderadoName"
        label="Nombre completo del apoderado:"
        placeholder="Ej: Lina Maria De Castro Jaramillo"
        v-model="complaint.apoderado_nombre"
        :disabled="true"
      />
      <BaseInput
        ref="attorneyEmail"
        type="text"
        id="apoderadoEmail"
        label="Correo electrónico del apoderado:"
        placeholder="Ej: apoderado@ejemplo.com"
        v-model="complaint.apoderado_correo"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["complaint"],
  computed: {
    abroad: function () {
      if (this.complaint.codigo_pais != "COLOMBIA") {
        return "Si";
      } else {
        return "No";
      }
    },
    formOptions: function () {
      return this.$store.state.form;
    },
  },

  data() {
    return {
      loading: false,
      feedback: "",
      user: {},
      specialCondition: 0,
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      optionsApoderado: [
        { id: 1, name: "Actúo en nombre propio" },
        { id: 2, name: "Soy apoderado" },
      ],
      optionsAttorney: [
        { id: false, name: "A nombre propio" },
        { id: true, name: "Soy apoderado" },
      ],
      optionsMunicipalities: [],
    };
  },
  async created() {
    await this.getUserDetail();     
    await this.getMunicipios();
  },
  methods: {
    async getUserDetail() {
      let identificationType_id = 1; // Por defecto 1
      const objIdentificationType = this.$store.state.form.optionsIdentificationType?.filter(
        identificationType => identificationType.name == this.$props.complaint?.tipo_id_CF
        );
        if (objIdentificationType.length > 0) {
          identificationType_id = objIdentificationType[0].id;
        }
      // Si la queja está cerrada se trae el histórico del usuario.
      let result = null;
      this.loading = true;
      if (this.$props.complaint?.user_history_id) {
        result = await this.$api.getQueryPersonHistory(
          `?identification_number=${this.$props.complaint?.numero_id_CF}&user_history=${this.$props.complaint?.user_history_id}`
        );
      } else {
        result = await this.$api.getQueryPerson(
          `?identification_number=${this.$props.complaint?.numero_id_CF}&identification_type=${identificationType_id}`
        );
      }
      if (result.error) {
        this.showToast(
          "error",
          "Error recuperando información del usuario asociado a la queja"
        );
        return;
      }
      if (result.data) {
        if (!this.$props.complaint?.user_history_id) {
          const user_back = result.data.results[0];
          this.user = {
            first_name: user_back.user_id.first_name,
            last_name: user_back.user_id.last_name,
            business_name: user_back?.user_id.business_name,
            phone: user_back.user_id.phone,
            email: user_back.user_id.email,
            person: {
              person_type: result.data.person_type,
              birth_date: user_back.birth_date,
              identification_number: this.$props.complaint?.numero_id_CF,
              identification_type: {
                name: this.$props.complaint?.tipo_id_CF,
              },
            },
            departamento_cod: user_back.user_id.departamento_cod,
            municipio_cod: user_back.user_id.municipio_cod,
            address: user_back.user_id.address,
          };
        } else if (this.$props.complaint.user_history_id) {
          const user = result.data.results[0];

          this.user = {
            first_name: user.first_name,
            last_name: user.last_name,
            business_name: user?.business_name,
            phone: user.phone,
            email: user.email,
            person: {
              person_type:
                this.$props.complaint.tipo_persona === "Natural" ? 1 : 2,
              birth_date: user.birth_date,
              identification_type: { name: this.$props.complaint.tipo_id_CF },
              identification_number: this.$props.complaint.numero_id_CF,
            },

            departamento_cod: user?.departamento_cod,
            municipio_cod: user?.municipio_cod,
            address: user?.address,
          };
        }
      }
      this.loading = false;
    },
    async getMunicipios() {
      if (this.user.departamento_cod == "") {
        return;
      }
      let options = await this.$store.dispatch(
        "form/loadMunicipalitiesOptions",
        { department: this.user.departamento_cod }
      );
      this.optionsMunicipalities = options;
    },
  },
};
</script>